<template>
  <div class="d-inline">
    <div :id="_uid.toString()" class="userBadge" :class="{ text }">
      {{ userName }}
    </div>
    <b-popover
      v-if="user"
      :target="_uid.toString()"
      triggers="hover"
      placement="top"
    >
      <b-media>
        <template #aside>
          <user-avatar :uid="uid" />
        </template>
        <strong>{{ user.userName }}</strong>
        <div v-if="user.name" class="ellipsis">
          <b-icon-person-fill />
          {{ user.name }}
        </div>
        <div v-if="user.email" class="ellipsis">
          <b-link :href="`mailto:${user.email}`" class="card-link">
            <b-icon-envelope-fill />
            {{ user.email }}
          </b-link>
        </div>
        <div v-if="user.discordId" class="ellipsis">
          <b-link
            :href="`https://discord.com/users/${user.discordId}`"
            class="card-link"
            target="_blank"
          >
            <b-icon-discord />
            Discord</b-link
          >
        </div>
      </b-media>
    </b-popover>
  </div>
</template>
<script>
import UserModuleMixin from "../mixins/UserModuleMixin";
import UserAvatar from "./UserAvatar.vue";
export default {
  components: { UserAvatar },
  name: "User",
  mixins: [UserModuleMixin],
  props: {
    uid: {
      type: String,
      required: true
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userName() {
      return this.user ? this.user.userName : "betöltés";
    },
    user() {
      return this.userModuleItems.find((u) => u.uid === this.uid);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.userBadge {
  //font-weight: 700;
  display: inline-block;
  color: $primary;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:not(.text) {
    padding: 1px 4px;
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    border-radius: 4px;
    background-color: mix($primary, transparent, 5%);
    &:hover {
      background-color: mix($primary, transparent, 10%);
    }
  }

  &:hover {
    color: mix($primary, black, 80%);
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
}
</style>
