<template>
  <div
    class="gameLogo"
    :style="{
      '--notch': `url(${require('@/assets/gameavatar-notch.svg?data')})`
    }"
  >
    <template v-if="game.logoUrl">
      <b-img fluid-grow rounded thumbnail :src="game.logoUrl" />
    </template>
    <template v-else>
      <div class="gameAvatar d-flex justify-content-center align-items-center">
        <template v-if="favicon && !faviconFailed">
          <b-img
            :src="favicon"
            class="lg-rounded"
            :style="{ 'min-width': '32px', 'max-width': '95px' }"
            @error="faviconFailed = true"
          />
        </template>
        <template v-else>
          <span class="text-primary">.</span>{{ game.name.substring(0, 1) }}
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    game: {
      required: true
    },
    favicon: {
      type: String || null
    }
  },
  data: () => ({ faviconFailed: false })
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.gameLogo {
  width: 212px;
  min-height: 212px;
  margin-top: -106px;
  padding: 26px;
  background-image: var(--notch);
  background-position: center center;
  background-repeat: no-repeat;
  .gameAvatar {
    font-size: 4.5em;
    height: 160px;
    font-weight: 700;
    background-color: white;
    border-radius: 80px;
    border: solid 10px $primary;
  }
}
</style>
