export default {
  computed: {
    jsonLD() {
      if (!this.game || !this.game.content) return {}
      const metaImage = this.game.meta.find(m => m.url === this.game.content.url);
      const image = metaImage && metaImage.image || (process.env.VUE_APP_API_URL + "games/" + this.game.id + "/screenshot")

      const jsonLD = {
        "@context": "http://www.schema.org",
        "@type": "Game",
        name: this.game.content.name,
        description: this.game.content.lead,
        image,
      }

      if (this.game.rating.average)
        jsonLD.aggregateRating = {
          "@type": "AggregateRating",
          ratingValue: this.game.rating.average,
          reviewCount: this.game.rating.count
        }

      if (this.game.content.playerCount)
        jsonLD.numberOfPlayers = {
          "@type": "QuantitativeValue",
          minValue: this.game.content.playerCount.from,
          maxValue: this.game.content.playerCount.to
        }

      if (this.game.content.ageLimit)
        jsonLD.audience = {
          "@type": "PeopleAudience",
          suggestedMinAge: this.game.content.ageLimit
        }

      if (this.game.content.pricing)
        jsonLD.offers = {
          "@type": "Offer",
          priceCurrency: this.game.content.pricing.currency,
          price: this.game.content.pricing.amount,
        }
      return jsonLD;
    }
  },
  methods: {
    setJsonLD(clear = false) {
      const script = document.querySelector("script[type='application/ld+json']")
      if (script) script.innerHTML = clear ? "" : JSON.stringify(this.jsonLD, null, 2)
    }
  },
  mounted() {
    this.setJsonLD()
  },
  beforeDestroy() {
    this.setJsonLD(true)
  },
  watch: {
    game() {
      this.setJsonLD()
    }
  }
};