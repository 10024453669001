export default (isoString) => {
  const dt = new Date(isoString)
  const now = new Date()

  const milliDiff = now.getTime() - dt.getTime();
  const minDiff = milliDiff / 1000 / 60;
  const hourDiff = minDiff / 60
  const dayDiff = hourDiff / 24
  const weekDiff = dayDiff / 7

  weekDiff;

  if (hourDiff < 24) return (now.getDate() === dt.getDate() ? '' : 'tegnap ') + dt.toLocaleTimeString("hu", { hour: "2-digit", minute: "2-digit" });
  if (dayDiff < 6) return dt.toLocaleString("hu", { weekday: "long", hour: "2-digit", minute: "2-digit" })

  return dt.toLocaleDateString();
}