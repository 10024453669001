<template>
  <b-avatar :src="src" variant="light">
    <span v-if="!src" class="initial">{{ userInitial }}</span>
  </b-avatar>
</template>
<script>
import UserModuleMixin from "../mixins/UserModuleMixin";
export default {
  name: "User",
  mixins: [UserModuleMixin],
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  computed: {
    userInitial() {
      return this.user ? this.user.userName.substring(0, 1) : "";
    },
    src() {
      return this.user ? this.user.picture : null;
    },
    user() {
      return this.userModuleItems.find((u) => u.uid === this.uid);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.initial {
  font-weight: 700;
  font-size: 16px;
  &::before {
    content: ".";
    color: $primary;
  }
}
</style>
