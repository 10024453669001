<template>
  <div>
    <b-overlay :show="loading" no-wrap spinner-variant="primary" />
    <div class="comment-list">
      <template v-if="!comments.length">
        <p class="text-center">
          Még senki nem szólt hozzá. Legyél te az első!
        </p>
      </template>
      <div v-for="(comment, i) in comments" :key="i">
        <b-media>
          <template #aside>
            <user-avatar :uid="comment.authorId" />
          </template>
          <div class="d-sm-flex align-items-center">
            <user :uid="comment.authorId" text class="mr-2" />
            <template v-if="comment.modified">
              <span class="mr-2">
                <small class="text-muted">
                  szerkesztve
                </small>
              </span>
            </template>
            <template
              v-if="currentUser && comment.authorId === currentUser.uid"
            >
              <b-link @click="editComment(comment.id, comment.content)">
                <small class="text-muted">
                  szerkesztés
                </small>
              </b-link>
              <b-modal
                v-model="editing"
                size="lg"
                title="Hozzászólás szerkesztése"
                :id="comment.id"
                @ok="edit"
              >
                <richtext-editor v-model="editedComment.content" ref="editor" />
              </b-modal>
            </template>
            <small class="ml-auto d-block">
              {{ displayDate(comment.createdAt) }}
            </small>
          </div>
          <div class="pt-1">
            <star-rating
              v-if="comment.rating"
              :value="comment.rating"
              readonly
              show-value
              :inactive="lastRating[comment.authorId] !== comment.id"
            />
            <richtext-editor
              v-if="comment.content"
              :value="comment.content"
              readonly
            />
          </div>
        </b-media>
        <hr />
      </div>
    </div>
    <template v-if="currentUser">
      <b-media>
        <template #aside>
          <user-avatar :uid="currentUser.uid" />
        </template>
        <b-form @submit.prevent="comment">
          <richtext-editor
            v-model="newComment.content"
            bottom-menu-bar
            ref="editor"
          />
          <div class="d-flex align-items-center">
            <star-rating v-model="newComment.rating" show-value />
            <b-button
              type="submit"
              variant="link"
              class="ml-auto"
              :disabled="!newComment.rating && !newComment.content"
            >
              Küldés <b-icon-arrow-right-circle-fill />
            </b-button>
          </div>
        </b-form>
      </b-media>
    </template>
    <template v-else>
      <p class="text-center">
        Hozzászólás írásához jelentkezz be!
      </p>
    </template>
  </div>
</template>
<script>
import GameModuleMixin from "../mixins/GameModuleMixin";
import UserModuleMixin from "../mixins/UserModuleMixin";
import RichtextEditor from "./RichtextEditor.vue";
import StarRating from "./StarRating.vue";
import User from "./User.vue";
import UserAvatar from "./UserAvatar.vue";
import displayDate from "@/utils/displayDate";
export default {
  name: "Comments",
  components: { User, UserAvatar, RichtextEditor, StarRating },
  mixins: [GameModuleMixin, UserModuleMixin],
  props: {
    gameId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    newComment: {},
    editedComment: {},
    editedCommentId: null,
    loading: false,
    editing: false
  }),
  mounted() {
    this.refresh();
  },
  computed: {
    game() {
      return this.gameModuleItems.find((g) => g.id === this.gameId);
    },
    comments() {
      return this.game ? this.game.comments || [] : [];
    },
    lastRating() {
      return this.comments.reduce((acc, cur) => {
        acc[cur.authorId] = cur.id;
        return acc;
      }, {});
    }
  },
  methods: {
    requestFocus() {
      this.$el.scrollIntoView({
        behavior: "smooth"
      });
      this.$refs.editor.requestFocus();
    },
    async comment() {
      try {
        this.loading = true;
        await this.createComment({
          gameId: this.gameId,
          data: this.newComment
        });
        if (this.newComment.rating) await this.fetchGames(true);
        else await this.refresh(true);
        this.newComment = {};
      } catch (error) {
        this.$bvToast.toast(
          `Nem sikerült a mentés (${error.message || error})`,
          {
            title: `Hiba`,
            variant: "danger",
            solid: true
          }
        );
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    editComment(id, content) {
      this.editedComment.content = content;
      this.editedCommentId = id;
      this.editing = true;
    },
    async edit() {
      try {
        this.loading = true;
        await this.updateComment({
          gameId: this.gameId,
          commentId: this.editedCommentId,
          data: this.editedComment
        });
        this.editedComment = {};
        this.editedCommentId = null;
        await this.refresh(true);
      } catch (error) {
        this.$bvToast.toast(
          `Nem sikerült a mentés (${error.message || error})`,
          {
            title: `Hiba`,
            variant: "danger",
            solid: true
          }
        );
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh(force) {
      if (force || (this.game && !this.game.comments)) {
        this.loading = true;
        await this.fetchComments({ gameId: this.gameId });
        this.loading = false;
      }
    },
    displayDate,
    userAvatar(id) {
      const user = this.userModuleItems.find((u) => u.uid === id);
      return (!!user && user.picure) || null;
    }
  },
  watch: {
    game() {
      this.refresh();
    }
  }
};
</script>
