<template>
  <div class="game-editor">
    <b-overlay :show="!game" no-wrap spinner-variant="primary" />
    <b-container class="pb-3" v-if="!!game">
      <div
        class="cover-wrapper"
        :style="{ '--bg': `url(${coverUrl})` }"
        @click="openGame"
        :title="`${game.content.name} megnyitása`"
      ></div>
      <div class="text-center d-flex flex-column align-items-center">
        <game-logo :game="game.content" :favicon="scrapedFavicon" />
        <h1>
          <strong>
            {{ game.content.name }}
          </strong>
        </h1>
        <p class="lead">
          <b-link :href="game.content.url" class="card-link" target="_blank">
            {{ displayUrl(game.content.url) }}
          </b-link>
        </p>
        <p>Beküldte <user :uid="game.createdBy" /></p>
        <p class="pointer" @click="$refs.comments.requestFocus()">
          <star-rating
            v-if="game.rating"
            :value="game.rating.average"
            readonly
            show-value
          />
        </p>
      </div>

      <page-title titleTag="h3" title="Játékadatok">
        <template #action>
          <b-button
            variant="text"
            class="text-primary"
            :disabled="!uid"
            :to="{ name: 'EditGame', params: { gameId: game.id } }"
          >
            <b-icon-pencil-square />
            <span class="d-none d-sm-inline"> Módosítás</span>
          </b-button>
        </template>
      </page-title>

      <b-card no-body>
        <b-card-body>
          <p class="text-center" v-if="game.content.lead">
            {{ game.content.lead }}
          </p>

          <div
            class="d-flex justify-content-center flex-wrap mx-auto mb-3"
            v-if="game.content.tags"
            style="max-width: 600px"
          >
            <b-tag
              v-for="tag in game.content.tags"
              :key="tag"
              no-remove
              variant="light"
              class="mx-1 mb-2"
            >
              #{{ tag }}
            </b-tag>
          </div>

          <div class="d-flex justify-content-center flex-wrap">
            <div class="big-badge" v-if="game.content.ageLimit">
              <b-icon-person-badge class="mr-1" variant="primary" />
              <post-fix-field-value
                :value="game.content.ageLimit"
                postfix="év"
              />
            </div>
            <div class="big-badge" v-if="game.content.playTime">
              <b-icon-clock class="mr-1" variant="primary" />
              <post-fix-field-value
                :value="game.content.playTime"
                postfix="perc"
              />
            </div>
            <div class="big-badge" v-if="game.content.playerCount">
              <b-icon-people class="mr-1" variant="primary" />
              <player-count-field-value :value="game.content.playerCount" />
            </div>
            <div class="big-badge" v-if="game.content.pricing">
              <b-icon-credit-card class="mr-1" variant="primary" />
              <pricing-field-value :value="game.content.pricing" />
            </div>
            <div class="big-badge" v-for="p in game.content.platforms" :key="p">
              <b-icon-grid class="mr-1" variant="primary" />
              {{ p }}
            </div>
          </div>

          <b-row class="mt-2">
            <b-col
              v-if="game.content.hunLocalized !== undefined"
              cols="12"
              sm="6"
              md="3"
            >
              {{ $t("hunLocalized") }}:<br class="d-none d-sm-inline" />
              <b-tag no-remove class="mt-1 mb-2 ml-2 ml-sm-0">{{
                game.content.hunLocalized ? $t("yes") : $t("no")
              }}</b-tag>
            </b-col>
            <b-col
              v-if="game.content.registeredUserNeeded"
              cols="12"
              sm="6"
              md="3"
            >
              {{ $t("registeredUserNeeded") }}:<br class="d-none d-sm-inline" />
              <b-tag no-remove class="mt-1 mb-2 ml-2 ml-sm-0">{{
                $t(game.content.registeredUserNeeded)
              }}</b-tag>
            </b-col>
            <b-col v-if="game.content.groupNeeded" cols="12" sm="6" md="3">
              {{ $t("groupNeeded") }}:<br class="d-none d-sm-inline" />
              <b-tag no-remove class="mt-1 mb-2 ml-2 ml-sm-0">{{
                $t(game.content.groupNeeded)
              }}</b-tag>
            </b-col>
            <b-col v-if="game.content.meetNeeded" cols="12" sm="6" md="3">
              {{ $t("meetNeeded") }}:<br class="d-none d-sm-inline" />
              <b-tag no-remove class="mt-1 mb-2 ml-2 ml-sm-0">{{
                $t(game.content.meetNeeded)
              }}</b-tag>
            </b-col>
          </b-row>
        </b-card-body>

        <external-links-field-value
          :value="game.content.externalLinks"
          flush
          :meta="game.meta"
        />
      </b-card>

      <b-row class="mt-3">
        <b-col cols="12" md="6" class="mb-3">
          <page-title titleTag="h5" title="Pro"></page-title>
          <b-card no-body>
            <b-list-group flush>
              <b-list-group-item
                v-if="!game.content.pro || !game.content.pro.length"
              >
                <p class="text-center text-primary m-0">
                  <small>
                    Nincs megadva
                  </small>
                </p>
              </b-list-group-item>
              <b-list-group-item v-for="(item, i) in game.content.pro" :key="i">
                <b-icon-check-circle variant="success" class="mr-2" />
                {{ item }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" class="mb-3">
          <page-title titleTag="h5" title="Contra"></page-title>
          <b-card no-body>
            <b-list-group flush>
              <b-list-group-item
                v-if="!game.content.contra || !game.content.contra.length"
              >
                <p class="text-center text-primary m-0">
                  <small>
                    Nincs megadva
                  </small>
                </p>
              </b-list-group-item>
              <b-list-group-item
                v-for="(item, i) in game.content.contra"
                :key="i"
              >
                <b-icon-dash-circle variant="danger" class="mr-2" />
                {{ item }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>

      <page-title titleTag="h5" title="Leírás"></page-title>
      <b-card class="mb-3">
        <richtext-editor
          :value="game.content.description"
          readonly
          :scroll="false"
        />
      </b-card>

      <page-title titleTag="h3" title="Vélemények">
        <template #action>
          <b-button
            variant="text"
            class="text-primary"
            v-if="authLoaded && uid"
            @click="$refs.comments.requestFocus()"
          >
            <b-icon-chat-square-text />
            <span class="d-none d-sm-inline"> Hozzászólok</span>
          </b-button>
        </template>
      </page-title>

      <b-card class="mb-3" @click="getFavicon">
        <comments :game-id="gameId" ref="comments" />
      </b-card>
    </b-container>
  </div>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";
import GameLogo from "../components/GameLogo.vue";
import RichtextEditor from "../components/RichtextEditor.vue";
import User from "../components/User.vue";
import StarRating from "../components/StarRating.vue";
import Comments from "../components/Comments.vue";
import GameModuleMixin from "../mixins/GameModuleMixin";
import GameJsonLDMixin from "../mixins/GameJsonLDMixin";
import AuthMixin from "../mixins/AuthMixin";
import displayUrl from "@/utils/displayUrl";
import axios from "axios";
import PlayerCountFieldValue from "../components/request_fields/PlayerCountFieldValue.vue";
import PricingFieldValue from "../components/request_fields/PricingFieldValue.vue";
import ExternalLinksFieldValue from "../components/request_fields/ExternalLinksFieldValue.vue";
import PostFixFieldValue from "../components/request_fields/PostFixFieldValue.vue";

export default {
  name: "GameView",
  components: {
    PageTitle,
    User,
    RichtextEditor,
    GameLogo,
    Comments,
    StarRating,
    PlayerCountFieldValue,
    PricingFieldValue,
    ExternalLinksFieldValue,
    PostFixFieldValue
  },
  mixins: [GameModuleMixin, AuthMixin, GameJsonLDMixin],
  data: () => ({ scrapedFavicon: "" }),
  computed: {
    gamePath() {
      return this.$route.params.gamePath;
    },
    gameId() {
      return this.$route.params.gameId || (this.game || {}).id;
    },
    game() {
      const game =
        this.gameModuleItems.find(
          (g) => this.gamePath && (g.content || {}).path === this.gamePath
        ) ||
        this.gameModuleItems.find((g) => g.id === this.$route.params.gameId);
      document.title =
        (game &&
          game.content &&
          game.content.name + " - Mit játsszunk online?") ||
        document.title;
      if (game) this.getFavicon();
      return game;
    },
    coverUrl() {
      return (
        this.game.coverUrl ||
        `${process.env.VUE_APP_API_URL}games/${this.game.id}/screenshot` ||
        `https://source.unsplash.com/collection/41219251/?v=${this.game.id}`
      );
    }
  },
  methods: {
    displayUrl,
    async getFavicon() {
      if (this.scrapedFavicon !== "") return;
      try {
        this.scrapedFavicon = null;
        const page = await axios.get(`games/${this.game.id}/favicon`);
        this.scrapedFavicon = page.data.logo || page.data.image;
      } catch (error) {
        console.error(error);
      }
    },
    openGame() {
      if (!this.game.content.url)
        return this.$bvModal.msgBoxOk("Nem tartozik link a játékhoz", {
          title: "Infó",
          okTitle: "Oké",
          centered: true
        });
      try {
        window.open(this.game.content.url, "_blank");
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.cover-wrapper {
  height: 200px;
  @media (min-width: 768px) {
    height: 350px;
  }
  background-image: var(--bg);
  background-position: top center;
  background-size: cover;
  border-radius: 0 0 16px 16px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  &::before {
    content: "";
    background-color: rgba(255, 255, 255, 0);
    color: mix($primary, black);
    transition: all 0.3s;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover::before {
    //content: "Kép megnyitása";
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.big-badge {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f0f5f5; // #e9e9e9;
  border-radius: 20px;
  padding: 0 12px;
  margin: 0 6px 12px 6px;
}
</style>
